<template>
  <div class="not-found">404</div>
</template>

<style scoped>
    .not-found{
      width:100vw;
      height:100vh;
      text-align: center
      
    }
</style>
